<template>
  <div class="logoRodape text-white"></div>
</template>

<script>
import { LOGO_WHITE } from './global';

export default {
  name: 'rodape',
  data() {
    return {
      logo: LOGO_WHITE,
    };
  },
};
</script>

<style>
.logoRodape {
  text-align: center;
  margin-top: 15px;
  font-size: 12px;
  display: block;
  position: relative;
}
</style>
